import React from 'react';
import ReactDOM from 'react-dom';
import './css/reset.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import pages
import Home from './page/Home';
import Error from './page/Error';
import Contact from './page/Contact';
import Workshop from './page/Workshop';
import Course from './page/Course';
import Gallery from './page/Gallery';
import Children_Art_course from './page/course_Info/Children_Art_course';
import Diploma_In_Fine from './page/course_Info/Diploma_In_Fine';
import Tanjore_Painting from './page/course_Info/Tanjore_Painting';
import Lower_higher_grade from './page/course_Info/Lower_higher_grade';
import Still_life_drawing from './page/course_Info/Still_life_drawing';
import Watercolor_painting from './page/course_Info/Watercolor_painting';
import Kids_Drawing_painting_craft from './page/course_Info/Kids_Drawing_painting_craft';
import AdmissionForm from './page/AdmissionForm';
import Examination from './page/exmination/Examination';
import Admin from './page/Admin';
import Login from './Admin/Login_Page';
import Amazing_kids from './Admin/TakeAttendance/Amazing_kids';
import BlossomMatSchool from './Admin/TakeAttendance/BlossomMatSchool';
import Tiny_tots_play_Home from './Admin/TakeAttendance/Tiny_tots_play_home';
import Sunrise_kids from './Admin/TakeAttendance/Sunrise_kids';
import Kaarunyabharathiarts from './Admin/TakeAttendance/KaarunyaBharathiArts';
import Online_class from './Admin/TakeAttendance/Online_class';
import StudentForm from './Admin/StudentForm';
import Filter_student_list from './Admin/Filter_student_list';


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/admin" element={<Login/>} />
        <Route path="admin/dashboard" element={<Admin />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/workshop" element={<Workshop />} />
        <Route path="/course" element={<Course />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="*" element={<Error />} />

          {/* Couser Info Pages */}

          <Route path="/course/children-art-course" element={<Children_Art_course />} />
          <Route path="/course/diploma-in-fine-arts" element={<Diploma_In_Fine />} />
          <Route path="/course/diploma-tanjore-painting" element={<Tanjore_Painting />} />
          <Route path="/course/free-hand-drawing-lower-higher-grade" element={<Lower_higher_grade />} />
          <Route path="/course/still-life-drawing&painting" element={<Still_life_drawing />} />
          <Route path="/course/water-painting-course" element={<Watercolor_painting />} />
          <Route path="/kids-drawing&painting&arts&craft" element={<Kids_Drawing_painting_craft />} />
          <Route path="/admission-from" element={<AdmissionForm />} />
          <Route path="/examination-result" element={<Examination />} />
    
          {/*Take Attendance*/}
          <Route path="/admin/dashboard/Amazing-kids/take-attendance" element={<Amazing_kids />} />
          <Route path="/admin/dashboard/blossom-mat-school/take-attendance" element={<BlossomMatSchool/>} />
          <Route path="/admin/dashboard/tiny-tots-play-home/take-attendance" element={<Tiny_tots_play_Home/>} />
          <Route path="/admin/dashboard/sunrise-kids/take-attendance" element={<Sunrise_kids/>} />
          <Route path="/admin/dashboard/KaarunyaBharathiArts/take-attendance" element={<Kaarunyabharathiarts/>} />
          <Route path="/admin/dashboard/online-class/take-attendance" element={<Online_class/>} />
          <Route path="/admin/dashboard/add-students" element={<StudentForm/>} />
          <Route path="/admin/dashboard/Fliter-student-info" element={<Filter_student_list/>} />


      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// Performance measuring (optional)
reportWebVitals();
