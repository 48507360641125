import React, { useState } from "react";
import "./Filter_student.css";
import excel_icon from "../assets/icons/excel.png";

const Filter_student_list = () => {
  const [branch, setBranch] = useState("");
  const [admissionNo, setAdmissionNo] = useState("");
  const [status, setStatus] = useState("All"); // Default to "All"
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [message, setMessage] = useState(""); // To hold success, error, or no data message
  const [students, setStudents] = useState([]); // To hold the list of students
  const [loading, setLoading] = useState(false); // Loading state to show loading indicator

  // Fetch student data from the backend based on filters
  const handleFindStudentData = async () => {
    // Validate input fields (e.g., ensure dates are valid)
    if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
      setMessage("Error: 'From' date cannot be later than 'To' date.");
      setStudents([]);
      return;
    }

    // Prepare query parameters
    const params = new URLSearchParams({
      branch: branch || "", // If no branch selected, include an empty value to fetch all
      admissionNo: admissionNo || "", // If no admission number entered, include an empty value
      status: status || "All", // Default to "All" if status is not selected
      fromDate: fromDate || "", // If no fromDate is selected, leave it empty
      toDate: toDate || "", // If no toDate is selected, leave it empty
    });

    console.log("Request Parameters:", params.toString()); // Log request params for debugging

    setLoading(true); // Set loading to true when fetching data

    try {
      const response = await fetch(
        `https://kaaruapiserver.selfmade.plus/FilterStudentInfo.php?${params.toString()}`
      );
      const data = await response.json();

      setLoading(false); // Set loading to false after response is received

      console.log("API Response:", data.students); // Log the response to debug

  setStudents(data.students)
    } catch (err) {
      setLoading(false); // Set loading to false in case of error
      setMessage("Error fetching data from the server. Please try again.");
      setStudents([]); // Clear previous student data
    }
  };

  // Handle the Excel download
  const handleDownload = () => {
    const params = new URLSearchParams({
      branch: branch || "",
      admissionNo: admissionNo || "",
      status: status || "All",
      fromDate: fromDate || "",
      toDate: toDate || "",
      download_excel: "1",
    });

    window.location.href = `https://kaaruapiserver.selfmade.plus/FilterStudentInfo.php?${params.toString()}`;
  };

  return (
    <div className="Filter-student-list-container">
      <div className="heading">
        <h1>Search and Filter Student Details</h1>
      </div>
      <div className="Filter-student-list">
        <div className="excel-download" onClick={handleDownload}>
          <img src={excel_icon} alt="excel" />
          Download Excel Sheet
        </div>
        <div className="Filter-list-container">
          <div className="filter-options">
            <div className="items">
              <select
                onChange={(e) => setBranch(e.target.value)}
                value={branch}
              >
                <option value="">Choose Branch</option>
                <option value="AmazingKids">Amazing kids</option>
                <option value="blossomschool">Blossom Mat. school</option>
                <option value="tinytotsplay">Tiny tots play Home</option>
                <option value="sunrise">Sunrise kids</option>
                <option value="kaarunyabharathiarts">
                  KaarunyaBharathiArts
                </option>
                <option value="online">Online</option>
              </select>
            </div>
            <div className="items">
              <input
                type="text"
                placeholder="Student Admission No."
                onChange={(e) => setAdmissionNo(e.target.value)}
                value={admissionNo}
              />
            </div>
            <div className="items">
              <select
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <option value="All">All</option>
                <option value="P">Present</option>
                <option value="A">Absent</option>
              </select>
            </div>
            <div className="items">
              <div className="from-date">
                <label htmlFor="">From</label>
                <input
                  type="date"
                  onChange={(e) => setFromDate(e.target.value)}
                  value={fromDate}
                />
              </div>
              <div className="to-date">
                <label htmlFor="">To</label>
                <input
                  type="date"
                  onChange={(e) => setToDate(e.target.value)}
                  value={toDate}
                />
              </div>
            </div>
            <div className="items">
              <button onClick={handleFindStudentData} disabled={loading}>
                {loading ? "Loading..." : "Find Student Information"}
              </button>
            </div>
          </div>
        </div>

        {message && <div className="message">{message}</div>}

        <div className="student-list-results">
          {students.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>Name</th>
                  {/* <th>Branch</th> */}
                  <th>Admission No.</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student, index) => (
                  <tr key={student.admissionNumber}>
                    <td>{index + 1}</td>
                    <td>{student.name}</td>
                    {/* <td>{student.branch}</td> */}
                    <td>{student.admissionNumber}</td>
                    <td>{student.status === "P" ? "Present" : "Absent"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No students found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filter_student_list;
