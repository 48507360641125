import React from 'react'
import Nav from '../../componet/Nav'
import Footer from '../../componet/Footer'
import Hero_Section from '../../Examination/Hero_Section'
import Student_List from '../../Examination/Student_List'

const Examination = () => {
  return (
    <>
    <Nav/>
    <Hero_Section/>
    <Student_List/>
    <Footer/>
    </>
  )
}

export default Examination