import React from "react";
import './Hero_section.css'
import Map_icon from '../assets/icons/map.png'


const Hero_Section = () => {
  return (
    <div className="examination-hero-container">
      <div className="examination-hero">
        <div className="examination-date">
          <h1 className="date">March 2025 Result</h1>
        </div>
        {/* <div className="exam-date">
          <div className="exam-date-head">
            <h1>Schedule:</h1>
          </div>
          <div className="exam-time">
            <h1>Paper part A :</h1>
            <h2>8:30 Am to 11:00 Am</h2>
            <h1>Paper part B :</h1>
            <h2>11:30 Am to 02:00 Pm</h2>

            <div className="location">
              <div className="heading">
                <h1>Address:</h1>
              </div>
              <div className="address">
                <p>Blossom matriculation school, MM Nagar, Lal</p>
              </div>
              <div className="google-map">
                <a href="https://www.google.com/maps/place/Blossom+Matriculation+School/@12.7448627,77.8050515,1617m/data=!3m1!1e3!4m6!3m5!1s0x3bae70e482f6de65:0xba7a22562824f911!8m2!3d12.7454806!4d77.8047232!16s%2Fg%2F11c2nl2p2g?entry=ttu&g_ep=EgoyMDI1MDIxOS4xIKXMDSoASAFQAw%3D%3D" target="_black">
                <div className="buttton">
                  <img src={Map_icon} alt="examination-location"/>
                  <strong>
                    Google Map
                  </strong>
                </div>
                </a>
              </div>
            </div>
          </div>
        </div> */}
        <div className="wish">
          <p>Good luck with your March 2025 results!</p>
        </div>
      </div>
    </div>
  );
};

export default Hero_Section;
