import React, { useState } from 'react';
import './StudentForm.css'; // Import the CSS file for styling

const StudentForm = () => {
  const [admissionNumber, setAdmissionNumber] = useState('');
  const [studentName, setStudentName] = useState('');
  const [branch, setBranch] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Error states for validation
  const [errors, setErrors] = useState({
    admissionNumber: '',
    studentName: '',
    email: '',
    mobile: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setErrors({}); // Reset errors

    // Validation checks
    let formValid = true;
    const newErrors = {};

    if (!admissionNumber.trim()) {
      formValid = false;
      newErrors.admissionNumber = 'Admission number is required.';
    } else if (!/^[a-zA-Z0-9/]+$/.test(admissionNumber)) {
      formValid = false;
      newErrors.admissionNumber = 'Admission number must contain only letters, numbers, and "/".';
    }

    if (!studentName.trim()) {
      formValid = false;
      newErrors.studentName = 'Student name is required.';
    } else if (!/^[a-zA-Z\s]+$/.test(studentName)) {
      formValid = false;
      newErrors.studentName = 'Student name must only contain letters and spaces.';
    }

    if (!email.trim()) {
      formValid = false;
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formValid = false;
      newErrors.email = 'Please enter a valid email address.';
    }

    if (!mobile.trim()) {
      formValid = false;
      newErrors.mobile = 'Mobile number is required.';
    } else if (!/^\d{10}$/.test(mobile)) {
      formValid = false;
      newErrors.mobile = 'Mobile number must be 10 digits.';
    }

    // If validation fails, update the errors state
    if (!formValid) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    // Proceed with form submission if valid
    const data = {
      admissionNumber,
      studentName,
      branch,
      email,
      mobile,
    };

    try {
      const response = await fetch('https://kaaruapiserver.selfmade.plus/insertStudent.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      setLoading(false);

      if (result.success) {
        setSuccess(true);
        setAdmissionNumber('');
        setStudentName('');
        setBranch('');
        setEmail('');
        setMobile('');
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      } else {
        alert('Error inserting data.');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Add Student</h2>

      {success && (
        <div className="success-overlay" onClick={() => setSuccess(false)}>
          <div className="success-message">
            <h3>Student Added Successfully!</h3>
            <button className="close-button" onClick={() => setSuccess(false)}>X</button>
          </div>
        </div>
      )}

      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="student-form">
        <div className="form-group">
          <label>Admission No.:</label>
          <input
            type="text"
            value={admissionNumber}
            onChange={(e) => setAdmissionNumber(e.target.value)}
            required
            placeholder="Enter Admission No."
          />
          {errors.admissionNumber && <p className="error">{errors.admissionNumber}</p>}
        </div>

        <div className="form-group">
          <label>Student Name:</label>
          <input
            type="text"
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
            required
            placeholder="Enter student name"
          />
          {errors.studentName && <p className="error">{errors.studentName}</p>}
        </div>

        <div className="form-group">
          <label>Branch:</label>
          <select
            value={branch}
            onChange={(e) => setBranch(e.target.value)}
            required
          >
            <option value="">Select Branch</option>
            <option value="amazing_kids">Amazing kids</option>
            <option value="blossom_Mat_school">Blossom Mat. school</option>
            <option value="tiny_tots_play_home">Tiny tots play Home</option>
            <option value="sunrise_kids">Sunrise kids</option>
            <option value="kaarunyabharathiarts">KaarunyaBharathiArts</option>
            <option value="online">Online</option>
          </select>
        </div>

        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter email address"
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>

        <div className="form-group">
          <label>Mobile:</label>
          <input
            type="tel"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
            placeholder="Enter mobile number"
          />
          {errors.mobile && <p className="error">{errors.mobile}</p>}
        </div>

        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default StudentForm;
