import React, { useState, useEffect } from "react";
import "./admin_hero_section.css";
import { useNavigate } from "react-router-dom";

const Admin_Hero_section = () => {
  const navigate = useNavigate();

  // Get the token from sessionStorage
  const token = sessionStorage.getItem("auth_token");
  const [greeting, setGreeting] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    if (!token) {
      // If no token exists in sessionStorage, redirect to login
      navigate("/admin");
      return;
    }

    // Send the token in the Authorization header for protected requests
    fetch("https://kaaruapiserver.selfmade.plus/protected_resource.php", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If response status is not 200, navigate to login page
          if (response.status === 401) {
            // Unauthorized, handle it properly
            navigate("/admin");
          } else {
            // Handle other types of errors (optional)
            console.error("Error:", response.statusText);
          }
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        // Navigate to login page on network error or any other error
        // navigate("/admin");
      });
  }, [token, navigate]);

  useEffect(() => {
    // Function to get the current greeting and time
    const updateTimeAndGreeting = () => {
      const currentDate = new Date();
      const currentHour = currentDate.getHours(); // Get current hour (0-23)

      // Get current time in hh:mm:ss format
      const currentTime = currentDate.toLocaleTimeString();

      // Determine greeting based on the time
      if (currentHour < 12) {
        setGreeting(
          "Good Morning! Start your day with a smile and make it productive!"
        );
      } else if (currentHour < 18) {
        setGreeting(
          "Good Afternoon! Keep up the great work, you’re doing awesome!"
        );
      } else {
        setGreeting("Good Evening! Relax and unwind, you’ve earned it!");
      }

      // Update the time every second
      setTime(currentTime);
    };

    // Call `updateTimeAndGreeting` every second
    const intervalId = setInterval(updateTimeAndGreeting, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run only once when the component mounts

  return (
    <div className="attendance-hero-container">
      <div className="attendance-hero">
        <div className="head">
          <h1>Daily Attendance</h1>
        </div>
        <div className="wish">
          <div className="time">
            <strong>{time}</strong> {/* Display time updated in seconds */}
          </div>
          <div className="wishing">
            <strong>{greeting}</strong> {/* Display greeting based on time */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin_Hero_section;
