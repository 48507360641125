import React from 'react'
import Admin_Hero_section from '../Admin/Admin_Hero_section'
import Admin_logout from '../Admin/Admin_logout'
import Available_class from '../Admin/Available_class'

const Admin = () => {
  return (
    <>
    <Admin_Hero_section/>
    <Admin_logout/>
    <Available_class/>
    </>
  )
}

export default Admin