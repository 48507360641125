import React from "react";
import "./MoreSction.css";

const MoreSection = () => {
  return (
    <div className="MoreSection-container">
      <div className="heading">More Options</div>
      <div className="MoreSection">
        <a href="/admin/dashboard/Fliter-student-info">
          <div className="items">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="bi bi-funnel-fill"
              viewBox="0 0 16 16"
            >
              <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
            </svg>
            Filter Student List
          </div>
        </a>

        <a href="/admin/dashboard/add-students">
          <div className="items">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="bi bi-person-fill-add"
              viewBox="0 0 16 16"
            >
              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
              <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
            </svg>
            Add New Student
          </div>
        </a>
      </div>
    </div>
  );
};

export default MoreSection;
