import React from "react";
import "./Available_class.css"; // Don't forget to import the CSS file
import { Link } from "react-router-dom";
import MoreSection from "./MoreSection";

const Available_class = () => {
  // List of available classes with corresponding Google Form links
  const classes = [
    {
      name: "Amazing kids",
      Link: "/admin/dashboard/Amazing-kids/take-attendance",
    },
    {
      name: "Blossom Mat. school",
      Link: "/admin/dashboard/blossom-mat-school/take-attendance",
    },
    {
      name: "Tiny tots play Home",
      Link: "/admin/dashboard/tiny-tots-play-home/take-attendance",
    },
    { name: "Sunrise kids", Link: "/admin/dashboard/sunrise-kids/take-attendance" },
    {
      name: "KaarunyaBharathiArts",
      Link: "/admin/dashboard/KaarunyaBharathiArts/take-attendance",
    },

    {  name: " Online", Link: "/admin/dashboard/online-class/take-attendance" },
  ];



  return (
    <>
    <div className="class-container">
      <h1>Available Classes</h1>
      <ul className="class-list">
        {classes.map((classItem,index) => (
          <li key={index} className="class-item">
           <Link to={classItem.Link}>
            <button
              className="class-buttons"
             >
              {classItem.name}
            </button>
            </Link>
          </li>
        ))}
      </ul>
    </div>
    <MoreSection/>
    </>
  );
};

export default Available_class;
