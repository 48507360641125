import React from "react";
import "../css/footer.css";
import Logo from "../assets/logo/logo.jpg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <img src={Logo} alt="Logo" className="footer-logo" />
        <p className="footer-text">Learn to draw with passion and creativity!</p>
        <div className="footer-links">
          <Link to={''} className="footer-link">About Us </Link>
          <Link to={'/course'}  className="footer-link">Courses </Link>
          <Link to={'/contact'}  className="footer-link">Contact us </Link>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-copyright">© 2024 Kaarunya Bharathi Arts. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
