// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';  // Import Axios for making HTTP requests
import './Login.css';  // Import the CSS file
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate=useNavigate()
  const [username, setUsername] = useState('');  // Username state
  const [password, setPassword] = useState('');  // Password state
  const [loading, setLoading] = useState(false);  // Loading state
  const [error, setError] = useState('');  // Error state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);  // Set loading state to true
    setError('');  // Clear previous errors

    try {
      // Replace this URL with your backend login endpoint
      const response = await axios.post('https://kaaruapiserver.selfmade.plus', {
        username,
        password,
      });

      // Handle successful response (you might want to store a token or navigate to another page)
      alert('Login successful!');
      sessionStorage.setItem("auth_token",response.data.token)
      navigate("/admin/dashboard")
    } catch (err) {
      // Handle error (wrong credentials or server error)
      setError('Invalid username or password');
      console.error('Login failed:', err);
    } finally {
      setLoading(false);  // Set loading state to false once the request is done
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              placeholder="Enter your username"
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
            />
          </div>
          {error && <div className="error-message">{error}</div>} {/* Display error message */}
          <button type="submit" className="btn" disabled={loading}>
            {loading ? (
              <div className="spinner"></div>  // Loading spinner
            ) : (
              'Login'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
