import React, { useEffect, useState } from "react";
import "../css/Slider.css";
import promo_Img from "../assets/other_img/cute-painter.png";
import { Link } from "react-router-dom";

const Slider = () => {
  const [promoIndex, setPromoIndex] = useState(0);
  const promoTexts = ["Successful", "Colorful", "Bright"];
  const [isFading, setIsFading] = useState(false);
  const [isOpenFromSelect, setIsOpenFromSelect] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setPromoIndex((prevIndex) => (prevIndex + 1) % promoTexts.length);
        setIsFading(false);
      }, 500); // Match this duration with CSS transition
    }, 2000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
    <div className="slider_container">
      <div className="slider">
        <div className="left">
          <div className="content">
            <div className="heading">
              <h1>
                Empowering Kids to Inspire a{" "}
                <span
                  className={`promo-text ${isFading ? "fade-out" : "fade-in"}`}
                >
                  {" "}
                  {promoTexts[promoIndex]}
                </span>
              </h1>
            </div>
            <div className="sub-Content">
              <ul>
                <li>Become an Artist</li>
                <li>Nurture Your Passion</li>
                <li>Express Your Vision</li>
              </ul>
            </div>
            <div className="btn-container">
              <div className="btn" onClick={() => setIsOpenFromSelect(true)}>
                Admission Now
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="img">
            <img src={promo_Img} alt="promo" />
          </div>
        </div>
      </div>

      {/* option from */}
      <input type="checkbox" id="handleFromOption" checked={isOpenFromSelect} />
      <div className="Selection-container">
        <div
          className="overlay"
          onClick={() => setIsOpenFromSelect(false)}
        ></div>
        <div className="Selection">
          <div className="heading">
            <h1>Select: Adult or Child?</h1>
          </div>
          <div className="list">
            <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdltpK3Xt09B-4wT2_b8YvndgNpcQ1wAi1RuzqZX9ngIeJCzQ/viewform?usp=preview" target="_black">
              <div className="items">
                <strong>Child</strong>
              </div>
            </Link>
            <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdmEnirBvtBDWlEn76Q5mC6A-Qr4wBQYHcgjufLhotaTvboCw/viewform?usp=preview" target="_black">
              <div className="items">
                <strong>Adult</strong>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
