import React from "react";
import "./Student_list.css";

const Student_List = () => {
  //Level 1 Exam List
  const level_1_Exam = [
    
      { Name: "Sreenesh Arun", ResultMark: "A" },
      { Name: "B.Sri Saini", ResultMark: "B" },
      { Name: "Guruchandana.T.G", ResultMark: "B" },
      { Name: "K.A.Lugitaa", ResultMark: "A" },
      { Name: "V.D.Sugitha", ResultMark: "A" },
      { Name: "B.Rubashree", ResultMark: "A" },
      { Name: "Harshith.S", ResultMark: "A" },
      { Name: "Gowthamsri.V", ResultMark: "A" },
      { Name: "Akshara", ResultMark: "A" },
      { Name: "Pradhiksha.G", ResultMark: "A++" },
      { Name: "Shrinidhi", ResultMark: "A++" },
      { Name: "Harinisri", ResultMark: "A" },
      { Name: "Yaazhini.V", ResultMark: "A" },
      { Name: "K.Lekasanshitha", ResultMark: "A" },
      { Name: "Kayal.K", ResultMark: "B" },
      { Name: "Kaviya.k", ResultMark: "A" },
      { Name: "Navitha.P", ResultMark: "A" },
      { Name: "Charulathaa", ResultMark: "A" },
      { Name: "Suthanthira", ResultMark: "B" },
      { Name: "A.Tamil selvam", ResultMark: "B" },
  ];

  //Level 2 Exam List
  const level_2_Exam = [
  
      { Name: "S.D.Priyadharsini", ResultMark: "A" },
      { Name: "F.Bernard Ilavarasan", ResultMark: "A" },
      { Name: "F.Mathaias Ilambarudhi", ResultMark: "B" },
      { Name: "B.Kishore", ResultMark: "A" },
      { Name: "D.Chaaru Varshini", ResultMark: "A" },
      { Name: "D.Aathishan", ResultMark: "A++" },
      { Name: "V.Hareesh", ResultMark: "A" },
      { Name: "M.Sabarinath", ResultMark: "C" },
      { Name: "T.Samiksha", ResultMark: "A++" },
      { Name: "S.P.Yojana", ResultMark: "A++" },
      { Name: "B.Aushikha", ResultMark: "A++" },
      { Name: "S.S.Devisri", ResultMark: "A" },
      { Name: "S.S.Diyasri", ResultMark: "B" },
      { Name: "K.A.Samyuktha", ResultMark: "A++" },
      { Name: "S.Adhithi", ResultMark: "A" },
      { Name: "A.Bhavyashree", ResultMark: "A" },
      { Name: "Priyadharshini.T", ResultMark: "A++" },
      { Name: "G.Kavishree", ResultMark: "A" },
  ];

  return (
    <>
      <div className="student-list-container">
        <div className="head">
          <h1>Exam Results - March 2025</h1>
        </div>
        <div className="student-list">
          <div className="items">
            <div className="heading">
              <h1>Level I Exam</h1>
            </div>
            <div className="student-name-list">
              <table>
                <tr>
                  <th>S.No.</th>
                  <th>Student Name</th>
                  <th>Grade</th>
                </tr>
                {level_1_Exam.map((items, index) => (
                  <tr key={index}>
                    <td>{index + 1})</td>
                    <td>{items.Name}</td>
                    <td>{items.ResultMark}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          <div className="items">
            <div className="heading">
              <h1>Level II Exam</h1>
            </div>
            <div className="student-name-list">
              <table>
                <tr>
                  <th>S.No.</th>
                  <th>Student Name</th>
                  <th>Grade</th>
                </tr>
                {level_2_Exam.map((items, index) => (
                  <tr key={index}>
                    <td>{index + 1})</td>
                    <td>{items.Name}</td>
                    <td>{items.ResultMark}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
        <div class="notes_grade">
        <div class="head">Note:-</div>
        <div class="notes">
            <div class="list">
                <h3>A++</h3>
                <span>(90-100)</span>
            </div>
            <div class="list">
                <h3>A</h3>
                <span>(70-89)</span>
            </div>
            <div class="list">
                <h3>B</h3>
                <span>(50-69)</span>
            </div>
            <div class="list">
                <h3>C</h3>
                <span>(30-49)</span>
            </div>
        </div>
    </div>
        </div>
    </>
  );
};

export default Student_List;
