import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Amazing_kids.css"; // Ensure you have the styles for loading and success

const Online_class = () => {
  //Student data
  const [studentData, setStudentData] = useState([]);

  const [attendance, setAttendance] = useState(
    studentData.map(() => ({ status: "" })) // Initial status is empty for each student
  );
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State to store error messages

  // Handle radio button change
  const handleStatusChange = (index, status) => {
    setAttendance((prevAttendance) => {
      const updatedAttendance = [...prevAttendance];
      updatedAttendance[index] = { status };
      return updatedAttendance;
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    // Check if all students have their attendance marked
    const allSelected = attendance.every((item) => item.status !== "");

    if (!allSelected) {
      alert("Please mark attendance for all students.");
      return;
    }

    setLoading(true); // Show loading animation
    setSuccess(false); // Reset success message
    setErrorMessage(""); // Reset error message

    // Get today's date
    const today = new Date().toISOString().split("T")[0]; // Format as YYYY-MM-DD

    // Prepare the data to send to the backend
    const data = attendance.map((item, index) => ({
      admissionNumber: studentData[index].admissionNumber, // Student's Admission No.
      date: today,
      name: studentData[index].name, // Student name
      status: item.status, // Present/Absent status
    }));

    try {
      const response = await axios.post(
        "https://kaaruapiserver.selfmade.plus/attendance_online.php",
        { data }
      );

      if (response.data.success) {
        setSuccess(true); // Show success message
        setTimeout(() => {
          setSuccess(false); // Reset success message after 3 seconds
          // Reset attendance form
          setAttendance(studentData.map(() => ({ status: "" })));
        }, 3000);
      } else {
        // Display backend error message
        setErrorMessage(
          response.data.message ||
            "There was an error submitting the attendance."
        );
      }
    } catch (error) {
      console.error("Error submitting attendance:", error);
      setErrorMessage("There was an error submitting the attendance.");
    } finally {
      setLoading(false); // Hide loading animation after request completes
    }
  };

  //feach student data

  useEffect(() => {
    axios.get("https://kaaruapiserver.selfmade.plus/getstudentattenceinfo.php").then((data)=>{
        setStudentData(data.data.studentsData.online)
    }).catch((e)=>{
        alert("server issue",+e)
    })
  });

  return (
    <div className="Amazing-kids-container">
      <div className="Amazing-kids">
        <div className="heading">
          <h1>Online Class Take Attendance</h1>
        </div>
        <div className="student-list">
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Admission No.</th>
                <th>Student Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {studentData.map((student, index) => (
                <tr key={student.id}>
                  <td>{index + 1}</td>
                  <td>{student.admissionNumber}</td>
                  <td>{student.name}</td>
                  <td>
                    <input
                      type="radio"
                      className="Present_student_box"
                      name={`student_${index}`}
                      id={`Present_student_${index}`}
                      value="P"
                      checked={attendance[index]?.status === "P"}
                      onChange={() => handleStatusChange(index, "P")}
                      disabled={success} // Disable inputs after successful submission
                    />
                    <label htmlFor={`Present_student_${index}`}>P</label>
                    <input
                      type="radio"
                      className="Absent_student_box"
                      name={`student_${index}`}
                      id={`Absent_student_${index}`}
                      value="A"
                      checked={attendance[index]?.status === "A"}
                      onChange={() => handleStatusChange(index, "A")}
                      disabled={success} // Disable inputs after successful submission
                    />
                    <label htmlFor={`Absent_student_${index}`}>A</label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="attendance-btn"
            onClick={handleSubmit}
            disabled={loading || success} // Disable the button if loading or success
          >
            {loading
              ? "Submitting..."
              : success
              ? "Submitted!"
              : "Submit Attendance"}
          </button>
        </div>

        {errorMessage && (
          <div className="error-message">
            {errorMessage} {/* Display error message */}
          </div>
        )}
      </div>

      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}

      {success && (
        <div className="success-overlay">
          <div className="checkmark">
            <svg
              width="50"
              height="50"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 6L9 17L4 12"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="success-message">
            Attendance Submitted Successfully!
          </div>
        </div>
      )}
    </div>
  );
};

export default Online_class;
